import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import formatDuration from '../../util/formatDuration';

import './SectionMintHeader.css';

export const CLOSING_DATE = null; // new Date('January 1, 2023 00:00:00 GMT-07:00');

function SectionMintHeader({
  saleStatus,
  userPresaleSlotsAvailable,
  userCanMint,
  walletConnected,
  devMintsAvailable,
  userFreebiesAvailable,
  loadingContract,
  isContractAvailable,
}) {
  const [timeLeft, setTimeLeft] = useState();
  useEffect(() => {
    if (!CLOSING_DATE) return () => {};

    const updateTime = () => {
      setTimeLeft(Math.max(0, CLOSING_DATE.getTime() - Date.now()));
    };
    const timeInterval = setInterval(updateTime, 1000);
    return () => {
      clearInterval(timeInterval);
    };
  }, []);

  if (!isContractAvailable) return '';

  let title;
  let subtitle;
  let info;
  if (saleStatus === -1) {
    subtitle = 'Loading Contract...';
  } else if (devMintsAvailable > 0) {
    title = 'Developer Mint!';
    info = (
      <p className="SectionMintHeader-body SectionMintHeader-body--important">
        You can mint
        {' '}
        {devMintsAvailable}
        {' '}
        more tokens as a developer.
      </p>
    );
  } else if (saleStatus <= 0) {
    title = 'Soon!';
    info = (
      <>
        <p className="SectionMintHeader-body">
          Pre-sale on 7/22. Public sale on 7/23.
        </p>
        <p className="SectionMintHeader-body">
          Check
          {' '}
          <a href="https://twitter.com/buttpunks" className="AnchorText TextIcon TextIcon--twitter">ButtPunks on Twitter</a>
          {' '}
          for updates or
          {' '}
          <a href="https://discord.gg/XcYPjZ3TH9" className="AnchorText TextIcon TextIcon--discord">join us on Discord</a>
          {' '}
          if you have questions!
        </p>
        {loadingContract ? (
          <p className="SectionMintHeader-body">
            Loading pre-sale status...
          </p>
        ) : ''}
        {!loadingContract && !walletConnected ? (
          <p className="SectionMintHeader-body">
            Connect your wallet to check your pre-sale status.
          </p>
        ) : ''}
        {walletConnected && !loadingContract && !userPresaleSlotsAvailable ? (
          <p className="SectionMintHeader-body">
            You are not on the pre-sale list.
            Please reach out to us if you think there has been and error.
          </p>
        ) : ''}
        {!loadingContract && userFreebiesAvailable && userPresaleSlotsAvailable ? (
          <>
            <p className="SectionMintHeader-body SectionMintHeader-body--important">
              Thanks for being an OG ButtPunk luver
            </p>
            <p className="SectionMintHeader-body SectionMintHeader-body--listTitle">
              You are on the pre-sale list and will be able to mint
              {' '}
              <strong>
                {userFreebiesAvailable}
                {' '}
                free butt
                {userFreebiesAvailable !== 1 ? 's' : ''}
              </strong>
              {' '}
              and an additional
              {' '}
              <strong>
                {userPresaleSlotsAvailable * 20}
                {' '}
                discounted butts
              </strong>
              {' '}
              during the pre-sale!
            </p>
          </>
        ) : ''}
        {!loadingContract && !userFreebiesAvailable && userPresaleSlotsAvailable ? (
          <>
            <p className="SectionMintHeader-body SectionMintHeader-body--important">
              You are on the pre-order list
            </p>
            <p className="SectionMintHeader-body SectionMintHeader-body--listTitle">
              You are on the pre-sale list and will be able to mint
              {' '}
              <strong>
                {userPresaleSlotsAvailable * 20}
                {' '}
                discounted butts
              </strong>
              {' '}
              during the pre-sale!
            </p>
          </>
        ) : ''}
      </>
    );
  } else if (saleStatus === 1) {
    title = 'Pre-sale has started!';
    if (!walletConnected) {
      info = (
        <p className="SectionMintHeader-body">
          If you are on our pre-sale list
          you will be able to mint tokens after connecting your wallet.
        </p>
      );
    } else if (walletConnected && loadingContract) {
      info = (
        <p className="SectionMintHeader-body">
          Loading pre-sale status...
        </p>
      );
    } else if (userFreebiesAvailable) {
      subtitle = 'Thanks for being an OG ButtPunks supporter';
      info = (
        <p className="SectionMintHeader-body SectionMintHeader-body--important">
          Enjoy some free cheeks on the house!
          <br />
          You have
          {' '}
          {userFreebiesAvailable}
          {' '}
          free mintable butts remaining.
        </p>
      );
    } else if (userCanMint) {
      subtitle = 'Thanks for being an OG ButtPunks supporter';
      info = (
        <p className="SectionMintHeader-body SectionMintHeader-body--important">
          You have
          {' '}
          {userPresaleSlotsAvailable}
          {' '}
          mint batches remaining.
        </p>
      );
    } else {
      info = (
        <>
          <p className="SectionMintHeader-body SectionMintHeader-body--important">
            You are either not on the list or have used all available pre-sale mint batches.
            <br />
            But have no fear because the public sale is &lt; 24 hours away!
          </p>
          <p className="SectionMintHeader-body">
            Check
            {' '}
            <a href="https://twitter.com/buttpunks" className="AnchorText TextIcon TextIcon--twitter">ButtPunks on Twitter</a>
            {' '}
            for updates or
            {' '}
            <a href="https://discord.gg/XcYPjZ3TH9" className="AnchorText TextIcon TextIcon--discord">join us on Discord</a>
            {' '}
            if you have questions!
          </p>
        </>
      );
    }
  } else if (saleStatus === 2) {
    if (typeof timeLeft === 'number' && timeLeft) {
      title = 'Minting will close soon!';
      subtitle = `${formatDuration(timeLeft)} left`;
    } else {
      title = 'Public sale has started!';
    }
    if (loadingContract) {
      info = (
        <p className="SectionMintHeader-body">
          Loading...
        </p>
      );
    } else if (!userCanMint) {
      info = (
        <p className="SectionMintHeader-body">
          Connect to the Cronos network to mint tokens.
        </p>
      );
    } else {
      info = (
        <p className="SectionMintHeader-body">
          Check
          {' '}
          <a href="https://twitter.com/buttpunks" className="AnchorText TextIcon TextIcon--twitter">ButtPunks on Twitter</a>
          {' '}
          for updates or
          {' '}
          <a href="https://discord.gg/XcYPjZ3TH9" className="AnchorText TextIcon TextIcon--discord">join us on Discord</a>
          {' '}
          if you have questions! See new butts revealed daily at 6 AM in
          {' '}
          <Link className="AnchorText" to="/gallery">the gallery</Link>
          !
        </p>
      );
    }
  } else if (saleStatus === 3 || saleStatus === 4) {
    title = 'Sold out!';
    info = (
      <p className="SectionMintHeader-body">
        Trading is available on all Cronos marketplaces.
      </p>
    );
  }
  return (
    <div className="SectionMintHeader">
      <h2 className="SectionMintHeader-headline">
        {title}
      </h2>
      {subtitle ? (
        <h3 className="SectionMintHeader-subheadline">
          {subtitle}
        </h3>
      ) : ''}
      {info}
    </div>
  );
}
SectionMintHeader.defaultProps = {
  saleStatus: -1,
  userPresaleSlotsAvailable: 0,
  userCanMint: false,
  walletConnected: false,
  devMintsAvailable: 0,
  userFreebiesAvailable: 0,
  loadingContract: false,
  isContractAvailable: false,
};
SectionMintHeader.propTypes = {
  saleStatus: PropTypes.number,
  userPresaleSlotsAvailable: PropTypes.number,
  userCanMint: PropTypes.bool,
  walletConnected: PropTypes.bool,
  devMintsAvailable: PropTypes.number,
  userFreebiesAvailable: PropTypes.number,
  loadingContract: PropTypes.bool,
  isContractAvailable: PropTypes.bool,
};

export default SectionMintHeader;
