export default [
  {
    name: 'Cronos Mainnet',
    short_name: 'cro',
    chain: 'CRO',
    network: 'cronos',
    chain_id: 25,
    rpc_url: 'https://evm.cronos.org',
    // rpc_url: 'https://cronos.w3node.com/ef313a95b78f8cb64d429455215380719920f123e3fcb7c69044ee0aa598dcdc/api',
    scanner_url: 'https://cronoscan.com/',
    native_currency: {
      symbol: 'CRO',
      name: 'Cronos',
      decimals: '18',
    },
  },
  {
    name: 'Cronos Testnet',
    short_name: 'tcro',
    chain: 'TCRO',
    network: 'tcronos',
    chain_id: 338,
    rpc_url: 'https://evm-t3.cronos.org',
    scanner_url: 'https://cronos.crypto.org/explorer/testnet3/',
    native_currency: {
      symbol: 'TCRO',
      name: 'Test Cronos',
      decimals: '18',
    },
  },
  {
    name: 'Ethereum Mainnet',
    short_name: 'eth',
    chain: 'ETH',
    network: 'mainnet',
    chain_id: 1,
    rpc_url: 'https://mainnet.infura.io/v3/%API_KEY%',
    scanner_url: 'https://etherscan.io',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
    },
  },
  {
    name: 'Ethereum Ropsten',
    short_name: 'rop',
    chain: 'ETH',
    network: 'ropsten',
    chain_id: 3,
    rpc_url: 'https://ropsten.infura.io/v3/%API_KEY%',
    scanner_url: 'https://ropsten.etherscan.io',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
    },
  },
  {
    name: 'Ethereum Rinkeby',
    short_name: 'rin',
    chain: 'ETH',
    network: 'rinkeby',
    chain_id: 4,
    rpc_url: 'https://rinkeby.infura.io/v3/%API_KEY%',
    scanner_url: 'https://rinkeby.etherscan.io',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
    },
  },
  {
    name: 'Ethereum Görli',
    short_name: 'gor',
    chain: 'ETH',
    network: 'goerli',
    chain_id: 5,
    rpc_url: 'https://goerli.infura.io/v3/%API_KEY%',
    scanner_url: 'https://goerli.etherscan.io',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
    },
  },
  {
    name: 'Ethereum Kovan',
    short_name: 'kov',
    chain: 'ETH',
    network: 'kovan',
    chain_id: 42,
    rpc_url: 'https://kovan.infura.io/v3/%API_KEY%',
    scanner_url: 'https://kovan.etherscan.io',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
    },
  },
  {
    name: 'Ethereum Localhost:8545',
    short_name: 'local',
    chain: 'ETH',
    network: 'localhost',
    chain_id: 1337,
    rpc_url: 'http://127.0.0.1:8545',
    scanner_url: 'https://127.0.0.1:8545',
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
    },
  },
];
