import './PageInfo.css';
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import classnames from 'classnames';
import { useWeb3Context } from '../context/Web3Context';
import ButtonConnect from './ButtonConnect';
// import { apiGetAccountAssets } from '../service/ethApi';
import presaleListData from '../data/presaleList.json';
import freebieListData from '../data/freebieList.json';
import AnchorAddress from './AnchorAddress';
import AnchorWalletAddress from './AnchorWalletAddress';
import { getUSDExchange, weiToEth } from '../util/eth';

const GENERIC_ERROR = {
  code: -1,
  message: 'Unknown error',
};

function parseCsv(text) {
  const rows = text.split('\n');
  const firstRow = rows.splice(0, 1)[0];
  const colNames = firstRow.split('\t');
  const csvData = rows.map((row) => {
    const rowData = {};
    const parsedRow = row.split('\t');
    colNames.forEach((key, index) => {
      rowData[key] = parsedRow[index];
    });
    return rowData;
  });
  return csvData;
}

function PageInfo() {
  const { web3State } = useWeb3Context();
  const [saleDetails, setSaleDetails] = useState({});
  const [balanceData, setBalanceData] = useState({});
  const [loadingContract, setLoadingContract] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [loadingCroPrice, setLoadingCroPrice] = useState(false);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingPresale, setLoadingPresale] = useState(false);
  const [loadingFreebie, setLoadingFreebie] = useState(false);
  const [loadingBaseImg, setLoadingBaseImg] = useState(false);
  const [croPriceUsd, setCroPriceUsd] = useState(0);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [newBaseTokenUri, setNewBaseTokenUri] = useState('');
  const [newBaseImageExample, setNewBaseImageExample] = useState(null);
  const [newPresaleList, setNewPresaleList] = useState(presaleListData.data);
  const [newFreebieList, setNewFreebieList] = useState(freebieListData.data);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const isOwner = useMemo(
    () => web3State.connected && web3State.address === saleDetails.owner,
    [web3State.connected, web3State.address, saleDetails.owner],
  );
  const fetchSaleDetails = useCallback(async () => {
    try {
      if (!web3State.connected) return;
      setLoadingContract(true);
      const newSaleDetails = {};
      await Promise.all([
        (async () => {
          newSaleDetails.owner = await web3State.contract.methods.owner().call();
        })(),
        (async () => {
          newSaleDetails.currentCount = await web3State.contract.methods.totalSupply().call();
          newSaleDetails.currentCount = parseInt(newSaleDetails.currentCount, 10);
        })(),
        (async () => {
          newSaleDetails.owner = await web3State.contract.methods.owner().call();
        })(),
        (async () => {
          newSaleDetails.maxCount = await web3State.contract.methods.MAX_SUPPLY().call();
          newSaleDetails.maxCount = parseInt(newSaleDetails.maxCount, 10);
        })(),
        (async () => {
          newSaleDetails.status = await web3State.contract.methods.saleStatus().call();
        })(),
      ]);
      setSaleDetails(newSaleDetails);
      setLoadingContract(false);
    } catch (err) {
      setLoadingContract(false);
      throw new Error(err);
    }
  }, [web3State]);

  const fetchBalanceData = useCallback(async () => {
    try {
      if (!web3State.connected) return;
      setLoadingBalance(true);
      const newBalanceData = {};
      await Promise.all([
        (async () => {
          newBalanceData.myShares = await web3State.contract.methods
            .shares(web3State.address).call();
          newBalanceData.myShares = parseInt(newBalanceData.myShares, 10);
        })(),
      ]);

      if (!newBalanceData.myShares) return;

      await Promise.all([
        (async () => {
          newBalanceData.contractShares = await web3State.contract.methods.totalShares().call();
          newBalanceData.contractShares = parseInt(newBalanceData.contractShares, 10);
          newBalanceData.myReleaseRatio = newBalanceData.myShares / newBalanceData.contractShares;
        })(),
        (async () => {
          newBalanceData.contractReleased = await web3State.contract.methods.totalReleased().call();
          newBalanceData.contractReleased = parseInt(newBalanceData.contractReleased, 10);
        })(),
        (async () => {
          newBalanceData.myReleased = await web3State.contract.methods
            .released(web3State.address).call();
          newBalanceData.myReleased = parseInt(newBalanceData.myReleased, 10);
        })(),
        (async () => {
          newBalanceData.contractBalance = await web3State.web3.eth
            .getBalance(web3State.contractAddress);
          newBalanceData.contractBalance = parseInt(newBalanceData.contractBalance, 10);
        })(),
      ]);
      newBalanceData.contractTotal = newBalanceData.contractBalance
        + newBalanceData.contractReleased;
      newBalanceData.myTotal = newBalanceData.contractTotal * newBalanceData.myReleaseRatio;
      newBalanceData.myBalance = newBalanceData.myTotal - newBalanceData.myReleased;
      setBalanceData(newBalanceData);
      setLoadingBalance(false);
    } catch (err) {
      setLoadingBalance(false);
      throw new Error(err);
    }
  }, [web3State]);

  const fetchCroPrice = useCallback(
    async () => {
      setLoadingCroPrice(true);
      setCroPriceUsd(await getUSDExchange('CRO'));
      setLoadingCroPrice(false);
    },
    [],
  );

  useEffect(() => {
    (async () => {
      const exampleUri = saleDetails.currentCount > 0 ? await web3State.contract.methods.tokenURI(0).call() : '';
      setNewBaseTokenUri(exampleUri.replace(/\/0$/, '/'));
      setNewBaseImageExample(exampleUri);
    })();
  }, [web3State.contract.methods, saleDetails.currentCount]);

  useEffect(() => {
    (async () => {
      if (!newBaseTokenUri) return;
      setNewBaseImageExample(null);
      fetch(`${newBaseTokenUri}0`)
        .then((response) => response.json())
        .then((data) => setNewBaseImageExample(data));
    })();
  }, [newBaseTokenUri]);

  useEffect(() => {
    fetchCroPrice();
  }, [fetchCroPrice]);

  useEffect(() => {
    async function fetchData() {
      try {
        await Promise.all([
          fetchSaleDetails(),
          fetchBalanceData(),
        ]);
        setLastRefresh(new Date());
      } catch (err) {
        console.error(err);
        setError(err);
      }
    }
    fetchData();
  }, [fetchSaleDetails, fetchBalanceData]);

  useEffect(() => {
    if (!autoRefresh) return () => {};
    const refreshInterval = setInterval(async () => {
      try {
        await Promise.all([
          fetchCroPrice(),
          fetchBalanceData(),
        ]);
        setLastRefresh(new Date());
      } catch (err) {
        console.error(err);
        setError(err);
      }
    }, 60000);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [autoRefresh, fetchBalanceData, fetchCroPrice]);

  const setSaleStatus = useCallback(async (newSaleStatus) => {
    try {
      setError(null);
      setSuccess(null);
      setLoadingStatus(true);
      await web3State.contract.methods.setSaleStatus(newSaleStatus).send({
        from: web3State.address,
      });
      setSaleDetails({
        ...saleDetails,
        status: newSaleStatus,
      });
      setSuccess(`Sale status set to ${newSaleStatus}!`);
      setLoadingStatus(false);
    } catch (err) {
      setError((err && err.error) || err || GENERIC_ERROR);
      setLoadingStatus(false);
    }
  }, [saleDetails, web3State.contract.methods, web3State.address]);

  const withdraw = useCallback(async () => {
    try {
      setError(null);
      setSuccess(null);
      setLoadingWithdraw(true);
      const res = await web3State.contract.methods.release(web3State.address).send({
        from: web3State.address,
      });
      setSuccess('Withdraw successful!', res);
      setLoadingWithdraw(false);
      setBalanceData({
        ...balanceData,
        myBalance: 0,
      });
    } catch (err) {
      setError((err && err.error) || err || GENERIC_ERROR);
      setLoadingWithdraw(false);
    }
  }, [balanceData, web3State.contract.methods, web3State.address]);

  const addPresaleAddresses = useCallback(async () => {
    try {
      setError(null);
      setSuccess(null);
      setLoadingPresale(true);
      const presaleList = parseCsv(newPresaleList);
      const addressList = presaleList.map((item) => item.address);
      const numSlots = presaleList.map((item) => item.slots);
      const res = await web3State.contract.methods.addPresaleAddresses(addressList, numSlots).send({
        from: web3State.address,
      });
      setSuccess('Pre-sale list seeded successful!', res);
      setLoadingPresale(false);
    } catch (err) {
      setError((err && err.error) || err || GENERIC_ERROR);
      setLoadingPresale(false);
    }
  }, [newPresaleList, web3State.contract.methods, web3State.address]);

  const addFreebieAddresses = useCallback(async () => {
    try {
      setError(null);
      setSuccess(null);
      setLoadingFreebie(true);
      const freebieList = parseCsv(newFreebieList);
      const addressList = freebieList.map((item) => item.address);
      const numFree = freebieList.map((item) => item.freebies);
      const res = await web3State.contract.methods.addFreebieAddresses(addressList, numFree).send({
        from: web3State.address,
      });
      setSuccess('Freebie list seeded successful!', res);
      setLoadingFreebie(false);
    } catch (err) {
      setError((err && err.error) || err || GENERIC_ERROR);
      setLoadingFreebie(false);
    }
  }, [newFreebieList, web3State.contract.methods, web3State.address]);

  const setBaseImageUri = useCallback(async () => {
    try {
      setError(null);
      setSuccess(null);
      setLoadingBaseImg(true);
      const res = await web3State.contract.methods.setBaseURI(newBaseTokenUri).send({
        from: web3State.address,
      });
      setSuccess('Base image URI updated successful!', res);
      setLoadingBaseImg(false);
    } catch (err) {
      setError((err && err.error) || err || GENERIC_ERROR);
      setLoadingBaseImg(false);
    }
  }, [newBaseTokenUri, web3State.contract.methods, web3State.address]);

  return (
    <div className="PageInfo">
      <div className="PageInfo-inner">
        {loadingContract || loadingBalance || loadingCroPrice ? (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">
              loading
              {' '}
              {[
                loadingContract && 'contract data',
                loadingBalance && 'balance data',
                loadingCroPrice && 'market data',
              ].filter((l) => l).join(', ')}
              ...
            </h2>
          </div>
        ) : ''}
        {error || success ? (
          <div className="PageInfo-section">
            {error ? (
              <p style={{ fontSize: '12px', color: 'red' }}>
                {`Error${
                  error.code ? ` ${error.code}` : ''
                }: ${error.message || error}`}

              </p>
            ) : (
              ''
            )}
            {success ? (
              <p style={{ fontSize: '12px', color: 'green' }}>{success}</p>
            ) : (
              ''
            )}
          </div>
        ) : ''}
        <div className="PageInfo-section">
          <h2 className="PageInfo-sectionHeadline">wallet: </h2>
          {web3State.connected ? (
            <span className="SectionConnection-address">
              <AnchorWalletAddress />
            </span>
          ) : (
            <ButtonConnect />
          )}
        </div>
        <div className="PageInfo-section">
          <h2 className="PageInfo-sectionHeadline">contract: </h2>
          {web3State.contractAddress ? (
            <AnchorAddress
              chainId={web3State.chainId}
              address={web3State.contractAddress}
            />
          ) : (
            <span>[smart contract has not been deployed on this chain]</span>
          )}
        </div>
        {!saleDetails.maxCount ? '' : (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">supply: </h2>
            {saleDetails.maxCount ? (
              <span>
                {saleDetails.currentCount.toLocaleString()}
                {' '}
                /
                {' '}
                {saleDetails.maxCount.toLocaleString()}
              </span>
            ) : (
              <span>[cannot retrieve contract supply]</span>
            )}
          </div>
        )}
        {!balanceData.myReleaseRatio ? '' : (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">
              market:
              {' '}
            </h2>
            <p>
              1 CRO = $
              {croPriceUsd.toLocaleString()}
            </p>
          </div>
        )}
        {!balanceData.contractTotal ? '' : (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">total revenue: </h2>
            <p>
              {Math.round(weiToEth(balanceData.contractTotal)).toLocaleString()}
              {' '}
              CRO
              <br />
              $
              {(Math.round(weiToEth(balanceData.contractTotal)) * croPriceUsd).toLocaleString()}
            </p>
          </div>
        )}
        {!balanceData.myReleaseRatio ? '' : (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">
              my total revenue (
              {balanceData.myReleaseRatio * 100}
              %):
              {' '}
            </h2>
            <p>
              {Math.round(
                weiToEth(balanceData.contractTotal) * balanceData.myReleaseRatio,
              ).toLocaleString()}
              {' '}
              CRO
              <br />
              $
              {(Math.round(
                weiToEth(balanceData.contractTotal) * balanceData.myReleaseRatio,
              ) * croPriceUsd).toLocaleString()}
            </p>
          </div>
        )}
        {!balanceData.myBalance ? '' : (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">
              my available revenue
            </h2>
            <p>
              {Math.round(weiToEth(balanceData.myBalance)).toLocaleString()}
              {' '}
              CRO
              <br />
              $
              {(Math.round(weiToEth(balanceData.myBalance)) * croPriceUsd).toLocaleString()}
            </p>
            <br />
            <button
              className={
              classnames(
                'Button',
                {
                  'Button--disabled': !web3State.connected,
                  'Button--loading': loadingWithdraw,
                },
              )
            }
              type="button"
              disabled={loadingWithdraw || !web3State.connected}
              onClick={withdraw}
            >
              <span className="Button-text">
                Withdraw
              </span>
            </button>
          </div>
        )}
        {!web3State.connected || !balanceData.contractTotal ? '' : (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">
              auto-refresh revenue data:
              {' '}
            </h2>
            <p>
              Last sync:
              {' '}
              {lastRefresh.toLocaleDateString()}
              {' '}
              {lastRefresh.toLocaleTimeString()}
            </p>
            <br />
            <p>
              <label className="Toggle" htmlFor="auto-refresh">
                <input
                  className="Toggle-input"
                  type="checkbox"
                  id="auto-refresh"
                  defaultChecked={autoRefresh}
                  onChange={(e) => setAutoRefresh(e.target.checked)}
                />
                <span className="Toggle-slider" />
              </label>
            </p>
          </div>
        )}
        {!isOwner ? '' : (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">
              pre-sale list
            </h2>
            <textarea value={newPresaleList} onChange={(e) => setNewPresaleList(e.target.value)} />
            <br />
            <button
              className={classnames(
                'Button',
                {
                  'Button--disabled': !web3State.connected,
                  'Button--loading': loadingPresale,
                },
              )}
              type="button"
              disabled={loadingPresale || !web3State.connected}
              onClick={addPresaleAddresses}
            >
              <span className="Button-text">
                Submit
              </span>
            </button>
          </div>
        )}
        {!isOwner ? '' : (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">
              freebie list
            </h2>
            <textarea value={newFreebieList} onChange={(e) => setNewFreebieList(e.target.value)} />
            <br />
            <button
              className={classnames(
                'Button',
                {
                  'Button--disabled': !web3State.connected,
                  'Button--loading': loadingFreebie,
                },
              )}
              type="button"
              disabled={loadingFreebie || !web3State.connected}
              onClick={addFreebieAddresses}
            >
              <span className="Button-text">
                Submit
              </span>
            </button>
          </div>
        )}
        {!isOwner ? '' : (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">
              base image uri
            </h2>
            <input value={newBaseTokenUri} onChange={(e) => setNewBaseTokenUri(e.target.value)} />
            {newBaseImageExample ? (
              <div className="PageInfoExampleImg">
                <p>{newBaseImageExample.name}</p>
                <img src={newBaseImageExample.image} alt={newBaseImageExample.name} />
              </div>
            ) : (
              <p style={{ color: 'red' }}>Invalid Base URI</p>
            )}
            <br />
            <button
              className={
              classnames(
                'Button',
                {
                  'Button--disabled': !web3State.connected,
                  'Button--loading': loadingBaseImg,
                },
              )
            }
              type="button"
              disabled={loadingBaseImg || !web3State.connected}
              onClick={setBaseImageUri}
            >
              <span className="Button-text">
                Submit
              </span>
            </button>
          </div>
        )}
        {!isOwner ? '' : (
          <div className="PageInfo-section">
            <h2 className="PageInfo-sectionHeadline">status: </h2>
            <p>
              {(() => {
                if (!web3State.contractAddress) {
                  return 'n/a';
                } if (saleDetails.status === 0) {
                  return 'paused';
                } if (saleDetails.status === 1) {
                  return 'pre-sale active';
                } if (saleDetails.status === 2) {
                  return 'public sale active';
                }
                return 'active';
              })()}
            </p>
            <br />
            <div>
              <button
                className={
                  classnames(
                    'Button',
                    {
                      'Button--disabled': !web3State.connected || saleDetails.status === 0,
                      'Button--loading': loadingStatus,
                    },
                  )
                }
                type="button"
                disabled={!web3State.connected || loadingStatus || saleDetails.status === 0}
                onClick={() => setSaleStatus(0)}
              >
                <span className="Button-text">
                  Pause sale
                </span>
              </button>
            </div>
            <br />
            <div>
              <button
                className={
                  classnames(
                    'Button',
                    {
                      'Button--disabled': !web3State.connected || saleDetails.status === 1,
                      'Button--loading': loadingStatus,
                    },
                  )
                }
                type="button"
                disabled={!web3State.connected || loadingStatus || saleDetails.status === 1}
                onClick={() => setSaleStatus(1)}
              >
                <span className="Button-text">
                  Start pre-sale
                </span>
              </button>
            </div>
            <br />
            <div>
              <button
                className={
                  classnames(
                    'Button',
                    {
                      'Button--disabled': !web3State.connected || saleDetails.status === 2,
                      'Button--loading': loadingStatus,
                    },
                  )
                }
                type="button"
                disabled={!web3State.connected || loadingStatus || saleDetails.status === 2}
                onClick={() => setSaleStatus(2)}
              >
                <span className="Button-text">
                  Start public sale
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PageInfo;
