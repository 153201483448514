import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { shuffleArray } from '../../util/shuffleArray';

import DISCORD_ROLES from '../../data/discordRoles.json';

import { setPaginatorItems, usePaginatorContext } from '../Paginator/PaginatorContext';
import { useWeb3Context } from '../../context/Web3Context';

import { Accordion } from '../Accordion';
import { PageGalleryModal } from './PageGalleryModal';
import { PaginatorControls } from '../Paginator/PaginatorControls';

import './styles.css';

const INITIAL_STATE = {
  allTokens: [],
  traitCounts: {},
  traitPercents: {},
  numMinted: 0,
  loading: false,
  error: null,
};

function PageGallery() {
  const { web3State } = useWeb3Context();
  const { paginatorState, paginatorDispatch } = usePaginatorContext();
  const [state, setState] = useState(INITIAL_STATE);
  const [traitFilter, setTraitFilter] = useState({});
  const [controlsExpanded, setControlsExpanded] = useState(false);
  const [discordRoleFilter, setDiscordRoleFilter] = useState(false);
  const [tokenIdSearch, setTokenIdSearch] = useState();
  const [selectedToken, setSelectedToken] = useState(null);

  const filterCount = useMemo(() => {
    let count = 0;
    if (tokenIdSearch) count += 1;
    return count + Object.values(traitFilter).filter((val) => val).length;
  }, [tokenIdSearch, traitFilter]);

  const filteredTokens = useMemo(() => {
    let newFilteredTokens = state.allTokens;
    if (tokenIdSearch) {
      newFilteredTokens = newFilteredTokens.filter((token) => token.tokenId === `${tokenIdSearch}`);
    }
    const traitIds = Object.keys(traitFilter).filter((key) => traitFilter[key]);
    newFilteredTokens = !traitIds.length ? newFilteredTokens : newFilteredTokens
      .filter((token) => {
        if (traitIds.includes('Discord Roles') && (!token.roleTraits || !token.roleTraits.length)) return false;
        const nonRoleFilters = traitIds.filter((id) => id !== 'Discord Roles');
        if (!nonRoleFilters) return true;
        const matchingFilters = nonRoleFilters
          .map((id) => !!token.attributes
            .find((attr) => attr.trait_type === id && attr.value === traitFilter[id]))
          .filter((val) => val);
        return matchingFilters.length === nonRoleFilters.length;
      });
    return newFilteredTokens;
  }, [state.allTokens, traitFilter, tokenIdSearch]);

  const pageTokens = useMemo(() => {
    const tokenStartIndex = paginatorState.curPage * paginatorState.numPerPage;
    return filteredTokens
      .slice(tokenStartIndex, tokenStartIndex + paginatorState.numPerPage);
  }, [filteredTokens, paginatorState.curPage, paginatorState.numPerPage]);

  useEffect(() => {
    paginatorDispatch(setPaginatorItems(filteredTokens));
  }, [filteredTokens, paginatorDispatch]);

  useEffect(() => {
    (async () => {
      try {
        const dataUrl = 'https://storage.googleapis.com/buttpunks2/all.json';
        const res = await window.fetch(dataUrl);
        const data = await res.json();
        data.allTokens = data.allTokens.map((metaData) => {
          const roleTraits = DISCORD_ROLES.map((role) => {
            if (!metaData.attributes.length) return null;
            const customSeriesAttr = metaData.attributes.find((attr) => attr.trait_type === 'Custom Series');
            if (role.type === 'TraitCount' && metaData.attributes.length <= role.value && !customSeriesAttr) return role;
            const matchingAttr = metaData.attributes.find((attr) => attr.trait_type === role.type);
            if (matchingAttr && matchingAttr.value.includes(role.value)) return role;
            return null;
          }).filter((s) => s);
          return {
            ...metaData,
            roleTraits,
          };
        });
        data.allTokens = shuffleArray(data.allTokens);
        setState(data);
        paginatorDispatch(setPaginatorItems(data.allTokens));
      } catch (err) {
        console.error(err);
        setState({
          ...INITIAL_STATE,
          error: err,
        });
      }
    })();
  }, [paginatorDispatch]);

  return (
    <div className="PageGallery">
      <div className="PageGallery-inner">
        {state.loading && (
          <p className="PageGallery-loading">Loading...</p>
        )}
        {state.error && (
          <p className="PageGallery-error">{state.error}</p>
        )}
        <div className="PageGallery-main">
          <div className="PageGallery-info">
            <p className="PageGallery-count">
              {filteredTokens.length.toLocaleString()}
              {' '}
              butts
            </p>
            <div className="PageGallery-activeFilterList">
              {tokenIdSearch ? (
                <button
                  type="button"
                  className="PageGallery-activeFilter"
                  onClick={() => {
                    setTokenIdSearch(null);
                  }}
                >
                  Token #:
                  {' '}
                  {tokenIdSearch}
                </button>
              ) : ''}
              {Object.keys(traitFilter)
                .filter((traitId) => traitFilter[traitId])
                .map((traitId) => (
                  <button
                    key={traitId}
                    type="button"
                    className="PageGallery-activeFilter"
                    onClick={() => {
                      setTraitFilter({
                        ...traitFilter,
                        [traitId]: false,
                      });
                    }}
                  >
                    {traitId}
                    :
                    {' '}
                    {traitFilter[traitId]}
                  </button>
                ))}
            </div>
          </div>
          <div className="PageGallery-tokenList">
            {pageTokens.map((token) => (
              <button
                key={token.tokenId}
                type="button"
                className={classNames(
                  'PageGalleryImg',
                  {
                    'PageGalleryImg--owned': token.owner === web3State.address,
                  },
                )}
                onClick={() => setSelectedToken(token)}
              >
                <div className="PageGalleryImg-wrapper">
                  <img
                    key={token.name}
                    className="PageGalleryImg-img"
                    src={token.image}
                    alt={`ButtPunk #${token.tokenId}`}
                  />
                  <span className="PageGalleryImg-roleTraits">
                    {discordRoleFilter && token.roleTraits && `${token.roleTraits.length}` && token.roleTraits.map((t) => t.emoji).join('')}
                  </span>
                </div>
                <p className="PageGalleryImg-label">
                  {`ButtPunk #${token.tokenId}`}
                </p>
              </button>
            ))}
          </div>
          <PaginatorControls />
        </div>

        <div
          className={classNames('PageGalleryControls', {
            'PageGalleryControls--expanded': controlsExpanded,
          })}
        >
          <button
            className="PageGalleryControls-overlay"
            type="button"
            onClick={() => setControlsExpanded(false)}
            label="close controls"
          />
          <div className="PageGalleryControls-drawer">
            <button
              className="PageGalleryControls-trigger"
              type="button"
              onClick={() => setControlsExpanded(!controlsExpanded)}
            >
              <span className="PageGalleryControls-triggerIcon" />
            </button>
            <div className="PageGallery-controls">
              <h2 className="PageGalleryControls-title">
                Filter
                {' '}
                {filterCount
                  ? `(${filterCount})`
                  : ''}
              </h2>
              <input
                className="PageGallery-input"
                type="number"
                min="0"
                max={state.allTokens.length - 1}
                aria-label="Search for ButtPunk by Token ID"
                onChange={(event) => setTokenIdSearch(event.target.value)}
                placeholder="Token #..."
              />
              {Object.keys(state.traitCounts).map((traitId) => (
                <Accordion
                  className="PageGalleryTraitFilter"
                  key={traitId}
                  title={traitId}
                >
                  <div className="PageGalleryTraitFilter-items">
                    {Object.keys(state.traitCounts[traitId])
                      .sort((a, b) => state.traitCounts[traitId][b] - state.traitCounts[traitId][a])
                      .map((traitValueId) => (
                        <label
                          key={`${traitId}_${traitValueId}`}
                          className="PageGallery-checkbox PageGalleryTraitFilter-label"
                          htmlFor={`Input--${traitId}_${traitValueId}`}
                        >
                          <input
                            id={`Input--${traitId}_${traitValueId}`}
                            className="PageGalleryTraitFilter-input"
                            type="checkbox"
                            checked={traitFilter[traitId] === traitValueId}
                            onChange={(event) => {
                              setTraitFilter({
                                ...traitFilter,
                                [traitId]: event.target.checked ? traitValueId : false,
                              });
                            }}
                          />
                          <span className="PageGalleryTraitFilter-text">
                            {traitValueId}
                          </span>
                          <span className="PageGalleryTraitFilter-count">
                            {state.traitCounts[traitId][traitValueId].toLocaleString()}
                          </span>
                        </label>
                      ))}
                  </div>
                </Accordion>
              ))}
              <Accordion
                className="DiscordRoles"
                title="Discord Roles"
              >
                {/* <div>
                <label
                className="PageGallery-checkbox PageGallery-discordRoleToggle"
                htmlFor="custom-traitFilter"
                >
                <input
                id="custom-traitFilter"
                type="checkbox"
                defaultChecked={traitFilter['Discord Roles']}
                onClick={(event) => {
                  setTraitFilter({
                    'Discord Roles': event.target.checked,
                  });
                }}
                />
                Only show eligable butts
                </label>
              </div> */}
                <div>
                  <label className="PageGallery-checkbox PageGallery-discordRoleToggle" htmlFor="discord-roles">
                    <input
                      id="discord-roles"
                      type="checkbox"
                      defaultChecked={discordRoleFilter}
                      onClick={(event) => setDiscordRoleFilter(event.target.checked)}
                    />
                    Show roles in gallery
                  </label>
                  <p className="DiscordRoles-desc">
                    Role emojis will be visible in bottom right corner of butt image!
                  </p>
                </div>
                <ul className="DiscordRoles-list">
                  {DISCORD_ROLES.map((role) => (
                    <li key={role.title}>
                      {role.emoji}
                      {' '}
                      <strong>{role.title}</strong>
                      {' '}
                      -
                      {' '}
                      {role.desc || `${role.value}`}
                    </li>
                  ))}
                </ul>
              </Accordion>
            </div>
          </div>
        </div>
        <PageGalleryModal
          token={selectedToken}
          traitPercents={state.traitPercents}
          onClose={() => setSelectedToken(null)}
        />
      </div>
    </div>
  );
}

export default PageGallery;
