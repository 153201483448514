import Web3Modal from 'web3modal';
import WalletConnect from '@walletconnect/web3-provider';
// import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { DeFiConnector } from 'deficonnect';
// import WalletLink from 'walletlink';
import Web3 from 'web3';

import config from '../data/app.json';
import supportedChains from '../data/supportedChains';

const RPC_URLS = {
  25: supportedChains.find((chain) => chain.chain_id === 25).rpc_url,
  338: supportedChains.find((chain) => chain.chain_id === 338).rpc_url,
};

// import coinbaseImgSrc from '../media/coinbase-logo.webp';

export const WEB3_MODAL = new Web3Modal({
  // cacheProvider: true,
  providerOptions: {
    walletconnect: {
      package: WalletConnect,
      options: {
        infuraId: config.INFURA_ID,
        rpc: RPC_URLS,
      },
    },
    'custom-cdc': {
      display: {
        name: 'Crypto.com',
        description: 'Crypto.com | Wallet Extension',
      },
      options: {
        supportedChainIds: Object.keys(RPC_URLS),
        rpc: RPC_URLS,
        pollingInterval: 15000,
      },
      package: DeFiConnector,
      connector: async (PackageConnector, options) => {
        const connector = new PackageConnector({
          name: 'Cronos',
          supprtedChainTypes: ['eth'],
          supportedChainTypes: ['eth'],
          eth: options,
          cosmos: null,
        });
        await connector.activate();
        const provider = await connector.getProvider();
        return provider;
      },
    },
    // walletlink: {
    //   package: CoinbaseWalletSDK,
    //   options: {
    //     appName: 'ButtPunks V2',
    //     infuraId: process.env.INFURA_ID,
    //   },
    // },
    // 'custom-cb-bsc': {
    //   display: {
    //     name: 'Coinbase Wallet',
    //     description: 'Connect to your Coinbase Wallet',
    //   },
    //   options: {
    //     appName: 'ButtPunks',
    //     // appLogoUrl: CollectionImgSrc,
    //     darkMode: false,
    //   },
    //   package: WalletLink,
    //   connector: async (ProviderPackage, options) => {
    //     const walletLink = new ProviderPackage(options);

    //     const provider = walletLink.makeWeb3Provider(
    //       'https://bsc-dataseed.binance.org/',
    //       56,
    //     );

    //     await provider.enable();

    //     return provider;
    //   },
    // },
  },
});

export async function connect() {
  const provider = await WEB3_MODAL.connect();
  await provider.enable();
  const web3 = new Web3(provider);
  web3.eth.extend({
    methods: [
      {
        name: 'chainId',
        call: 'eth_chainId',
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });

  return {
    web3,
    provider,
  };
}

export async function clearModalCache() {
  await WEB3_MODAL.clearCachedProvider();
}

export async function disconnect(web3) {
  if (web3 && web3.currentProvider && web3.currentProvider.disconnect) {
    await web3.currentProvider.disconnect();
  }
  await clearModalCache();
}
