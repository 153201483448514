import React from 'react';
import classNames from 'classnames';
import AnchorAddress from './AnchorAddress';
import imgHashes from '../data/image-hashes.json';
// import rootCids from '../data/cids/root.json';
// import imgCids from '../data/cids/img.json';
import provenanceData from '../data/provenance.json';
import APP_CONFIG from '../data/app.json';

import './PageProvenance.css';

function PageProvenance() {
  return (
    <div className="PageProvenance">
      <div className="PageProvenance-inner">
        <div className="PageProvenance-section">
          <h1 className="PageProvenance-headline">
            {APP_CONFIG.PROJECT_NAME.toUpperCase()}
            {' '}
            PROVENANCE RECORD
          </h1>
          <p className="PageProvenance-blurb">
            This page presents the provenance record of each token that will ever exist.
            Every image is uploaded to IPFS as a directory.
            IPFS then generates a CID for the entire directory.
            This CID is unique to the directory of assets and altering the contents of the
            directory in any way will thus will change the CID.
            To read more about CIDs and their importance, check out
            {' '}
            <a href="https://docs.pinata.cloud/nfts" target="_blank" rel="noreferrer">Pinata’s article on the topic</a>
            . Note that images will not be uploaded to IPFS until after minting has been closed
            to preserve the blind mint / reveal process.
          </p>
          <p className="PageProvenance-blurb">
            Furthermore, we’ve also generated a hash for each image using the SHA-256 algorithm.
            We’ve then taken each of these hashes and concatenated them into one large string.
            This large string is then used to generate one final SHA-256 hash.
            This is the &quot;Final Proof Hash&quot; of the collection
            and is stored in the smart contract.
          </p>
        </div>
        <div className="PageProvenance-section">
          <h2 className="PageProvenance-headline">TLDR;</h2>
          <p className="PageProvenance-blurb">
            <strong>
              {APP_CONFIG.PROJECT_NAME}
              {' '}
              Contract Address:
            </strong>
            {' '}
            <code><AnchorAddress address={APP_CONFIG.CONTRACT_ADDRESSES[25]} /></code>
          </p>
          {/* <p className="PageProvenance-blurb">
            <strong>IPFS CID:</strong>
            {' '}
            <code>{rootCids.img}</code>
          </p> */}
          <p className="PageProvenance-blurb">
            <strong>Final Proof Hash:</strong>
            {' '}
            <code>{provenanceData.provenance}</code>
          </p>
        </div>
        <div className="PageProvenance-section">
          <h2 className="PageProvenance-headline">Concatenated Collection Hash String</h2>
          <div className="PageProvenance-codeBlockWrapper PageProvenance-blurb">
            <code className="PageProvenance-codeBlock">
              {Object.values(imgHashes).join('')}
            </code>
          </div>
        </div>
        <div className="PageProvenance-section">
          <h2 className="PageProvenance-headline">Collection Records</h2>
          <div className="PageProvenance-tableWrapper">
            <table className="PageProvenance-table">
              <thead>
                <tr>
                  <th>Token ID</th>
                  <th>SHA-256 Hash</th>
                  {/* <th>IPFS CID</th> */}
                </tr>
              </thead>
              <tbody>
                {Object.keys(imgHashes).map((tokenId) => (
                  <tr
                    key={tokenId}
                    className={classNames(
                      'TokenRow',
                      { 'TokenRow--isUnminted': APP_CONFIG.TOKEN_COUNT_FINAL >= 0 && tokenId >= APP_CONFIG.TOKEN_COUNT_FINAL },
                    )}
                  >
                    <td><code>{tokenId}</code></td>
                    <td><code>{imgHashes[tokenId]}</code></td>
                    {/* <td>
                      <a
                        href={`https://ipfs.io/ipfs/${imgCids[`${tokenId}.png`]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <code>
                          {imgCids[`${tokenId}.png`]}
                        </code>
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageProvenance;
