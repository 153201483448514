import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import PropTypes from 'prop-types';

import './styles.css';

Modal.setAppElement('#root');

export function PageGalleryModal({ token, traitPercents, onClose }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (!token) return;
    setModalIsOpen(true);
  }, [token]);

  function closeModal() {
    setModalIsOpen(false);
    onClose();
  }

  return (
    <Modal
      className="PageGalleryModal"
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal()}
      contentLabel="Example Modal"
    >
      {token && (
        <div className="PageGalleryModal-wrapper">
          <div className="PageGalleryModal-imgWrapper">
            <img
              className="PageGalleryModal-img"
              src={token.image}
              alt={`ButtPunk #${token.tokenId}`}
            />
          </div>
          <div className="PageGalleryModal-metaWrapper">
            <h2 className="PageGalleryModal-name">{token.name}</h2>
            <div className="PageGalleryModal-sectionWrapper">
              <h3 className="PageGalleryModal-sectionHeadline">
                Traits:
              </h3>
              <div className="PageGalleryModal-traits">
                {token.attributes.map((attr) => (
                  <div
                    className="PageGalleryModal-trait"
                    key={attr.trait_type}
                  >
                    <p className="PageGalleryModal-traitLabel">
                      {attr.trait_type}
                      :
                    </p>
                    <p className="PageGalleryModal-traitValue">
                      {attr.value}
                    </p>
                    <p className="PageGalleryModal-traitPercent">
                      {Math.round(traitPercents[attr.trait_type][attr.value] * 10000) / 100}
                      %
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {token.roleTraits && token.roleTraits.length ? (
              <div className="PageGalleryModal-sectionWrapper">
                <h3 className="PageGalleryModal-sectionHeadline">
                  Eligable Discord Roles:
                </h3>
                <div className="PageGalleryModal-roleTraits">
                  {token.roleTraits.map((role) => (
                    <div
                      className="PageGalleryModal-trait"
                      key={role.title}
                    >
                      {role.emoji}
                      {' '}
                      <strong>{role.title}</strong>
                      {' '}
                      -
                      {' '}
                      {role.desc || `${role.value}`}
                    </div>
                  ))}
                </div>
              </div>
            ) : ''}
            <div className="PageGalleryModal-links PageGalleryModal-sectionWrapper">
              <h3 className="PageGalleryModal-sectionHeadline">
                View on:
              </h3>
              <a
                className="AnchorText TextIcon TextIcon--ebisus"
                href={`https://app.ebisusbay.com/collection/butt-punks-v2/${token.tokenId}`}
                target="_blank"
                rel="noreferrer"
              >
                Ebisu&apos;s Bay
              </a>
            </div>
          </div>
        </div>
      )}
      <button className="PageGalleryModal-close" type="button" onClick={() => closeModal()}>X</button>
    </Modal>
  );
}

PageGalleryModal.defaultProps = {
  token: null,
  traitPercents: null,
  onClose: () => {},
};

PageGalleryModal.propTypes = {
  token: PropTypes.shape({
    tokenId: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    attributes: PropTypes.arrayOf(PropTypes.shape({
      trait_name: PropTypes.string,
      value: PropTypes.string,
    })),
    // eslint-disable-next-line react/forbid-prop-types
    roleTraits: PropTypes.arrayOf(PropTypes.object),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  traitPercents: PropTypes.object,
  onClose: PropTypes.func,
};

export default PageGalleryModal;
