import React, {
  createContext,
  useReducer,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

// STATE
const NUM_PER_PAGE = 12;

const INITIAL_STATE = {
  numPerPage: NUM_PER_PAGE,
  numPages: 0,
  curPage: 0,
  items: [],
};

// ACTIONS
export const ACTIONS = {
  PAGINATOR_ITEMS_SET: 'PAGINATOR_ITEMS_SET',
  PAGINATOR_PAGE_SET: 'PAGINATOR_PAGE_SET',
};
export function setPaginatorItems(items) {
  return [ACTIONS.PAGINATOR_ITEMS_SET, items];
}
export function setPaginatorPage(pageNum) {
  return [ACTIONS.PAGINATOR_PAGE_SET, pageNum];
}

// REDUCER
function REDUCER(state, [type, payload]) {
  switch (type) {
    case ACTIONS.PAGINATOR_ITEMS_SET:
      return {
        ...state,
        items: payload,
        curPage: 0,
        numPages: Math.ceil(payload.length / state.numPerPage),
      };
    case ACTIONS.PAGINATOR_PAGE_SET:
      if (state.numPages.length <= payload) throw new Error('Paginator page doesn\'t exist');
      return {
        ...state,
        curPage: payload,
      };
    default:
      return { ...state };
  }
}

const PaginatorContext = createContext({
  paginatorState: INITIAL_STATE,
  paginatorDispatch: () => {},
});

export function PaginatorProvider({
  children,
}) {
  const [paginatorState, paginatorDispatch] = useReducer(REDUCER, INITIAL_STATE);

  // wrap value in memo so we only re-render when necessary
  const providerValue = useMemo(() => ({
    paginatorState,
    paginatorDispatch,
  }), [paginatorState, paginatorDispatch]);

  return (
    <PaginatorContext.Provider value={providerValue}>
      {children}
    </PaginatorContext.Provider>
  );
}
PaginatorProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export function usePaginatorContext() {
  return useContext(PaginatorContext);
}
