import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

function Analytics({ cookiesAccepted }) {
  const location = useLocation();
  useEffect(() => {
    if (cookiesAccepted) {
      ReactGA.initialize('G-J8LRVVLNMZ');
    }
  }, [cookiesAccepted]);
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, [location]);
  return '';
}

Analytics.defaultProps = {
  cookiesAccepted: false,
};

Analytics.propTypes = {
  cookiesAccepted: PropTypes.bool,
};

export default Analytics;
