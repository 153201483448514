import { utils } from 'ethers';

export async function getUSDExchange(symbol = 'ETH') {
  const data = await fetch(`https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=USD`).then((response) => response.json());
  return data.USD;
}

export function weiToEth(val) {
  return val / 1000000000000000000;
}

export function bigNumberWeiToEth(bigNumberVal) {
  const val = utils.formatEther(bigNumberVal);
  const valSplit = val.split('.');
  return valSplit[0] !== '0' ? valSplit[0] : val;
}

export default {
  weiToEth,
  bigNumberWeiToEth,
  getUSDExchange,
};
