export default function formatDuration(duration) {
  let dur = duration;
  const days = Math.floor(dur / 86400000);
  dur -= days * 86400000;
  const hours = Math.floor(dur / 3600000);
  dur -= hours * 3600000;
  const minutes = Math.floor(dur / 60000);
  dur -= minutes * 60000;
  return [
    days && `${days} days`,
    (days || hours) && `${hours} hours`,
    ((days || hours) || minutes) && `${minutes} minutes`,
  ].filter((str) => !!str).join(', ');
}
