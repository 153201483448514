import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import OGLogoSrc from '../media/og-logo.png';
import V2LogoSrc from '../media/logo.png';
// import appData from '../data/app.json';
import './AppFooter.css';

function AppFooter({ className }) {
  return (
    <footer className={classnames('AppFooter', className)}>
      <div className="AppFooterSection AppFooterSection--contact">
        <div className="ContactItem">
          <span className="AppFooterContactLink AppFooterContactLink--projects">
            <a href="https://buttpunks.com/og/" className="ProjectLink ProjectLink--og">
              <img src={OGLogoSrc} alt="OG ButtPunks Logo" />
            </a>
            <a href="https://buttpunks.com" className="ProjectLink ProjectLink--v2">
              <img src={V2LogoSrc} alt="ButtPunks v2 Logo" />
            </a>
          </span>
          <span className="AppFooterContactLink AppFooterContactLink--general">
            Join us on
            {' '}
            <a href="https://discord.gg/XcYPjZ3TH9" className="AnchorText TextIcon TextIcon--discord">Discord</a>
            {' '}
            or hit us up on
            {' '}
            <a href="https://twitter.com/buttpunks" className="AnchorText TextIcon TextIcon--twitter">Twitter</a>
            !
          </span>
        </div>
      </div>
      <p className="AppFooterSection AppFooterSection--contact">
        <span className="AppFooterContactLink">
          Art &amp; Community
          {' '}
          <a href="https://twitter.com/patricklawler" className="AnchorText TextIcon TextIcon--twitter">Patrick Lawler</a>
        </span>
        <span className="AppFooterContactLink">
          Code &amp; Design
          {' '}
          <a href="https://twitter.com/folklorelabs" className="AnchorText TextIcon TextIcon--twitter">Folklore Labs</a>
        </span>
      </p>
      <p className="AppFooterSection AppFooterSection--contact AppFooterSection--thanks">
        <span className="AppFooterContactLink">
          And a special thanks to
          <br />
          <a href="https://twitter.com/everytimeicash" className="AnchorText TextIcon TextIcon--twitter">Wolfe</a>
          {', '}
          <a href="https://twitter.com/DegenBrains" className="AnchorText TextIcon TextIcon--twitter">Mike</a>
          {', and '}
          <a href="https://twitter.com/Mooseface66" className="AnchorText TextIcon TextIcon--twitter">Moose</a>
        </span>
      </p>
      <div className="AppFooterSection AppFooterSection--terms">
        <Link className="AnchorText" to="/terms">Terms &amp; Conditions</Link>
        <p className="AppFooterSection-legal">
          ButtPunks is not affiliated with LarvaLabs or CryptoPunks.
          ButtPunks is a parody of CryptoPunks.
        </p>
      </div>
    </footer>
  );
}

AppFooter.defaultProps = {
  className: '',
};

AppFooter.propTypes = {
  className: PropTypes.string,
};

export default AppFooter;
