import React from 'react';
import { PaginatorProvider } from '../Paginator/PaginatorContext';
import PageGallery from './PageGallery';

function PageGalleryWrapper() {
  return (
    <PaginatorProvider>
      <PageGallery />
    </PaginatorProvider>
  );
}

PageGalleryWrapper.defaultProps = {

};

PageGalleryWrapper.propTypes = {

};

export default PageGalleryWrapper;
