import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { BigNumber } from 'ethers';
import classnames from 'classnames';
import ReactGA from 'react-ga4';
import confettiBomb from '../../util/confettiBomb';
import appConfig from '../../data/app.json';
import supportedChains from '../../data/supportedChains';
import { bigNumberWeiToEth } from '../../util/eth';
import { useWeb3Context } from '../../context/Web3Context';
// import AnchorAddress from './AnchorAddress';
import ButtonConnect from '../ButtonConnect';
import AnchorWalletAddress from '../AnchorWalletAddress';
import SectionMintHeader, { CLOSING_DATE } from './SectionMintHeader';

import './SectionMint.css';
import AnchorAddress from '../AnchorAddress';

const GENERIC_ERROR = {
  code: -1,
  message: 'Unknown error',
};

const initialSaleDetails = {
  status: -1,
};
const MAX_MINT_OVERRIDE = null;

function SectionMint() {
  const { web3State } = useWeb3Context();
  const [numTokens, setNumTokens] = useState(1);
  // -1 = contract not deployed, 0 = no sale, 1 = presale, 2 = public sale, 3 = sold out
  const [saleDetails, setSaleDetails] = useState(initialSaleDetails);
  const [loading, setLoading] = useState(false);
  const [loadingContract, setLoadingContract] = useState(false);
  const [error, setError] = useState();
  const [userPresaleSlotsAvailable, setUserPresaleSlotsAvailable] = useState(0);
  const [userFreebiesAvailable, setUserFreebiesAvailable] = useState(0);
  const [devMintsAvailable, setDevMintsAvailable] = useState(0);
  const [txAddress, setTxAddress] = useState('');
  const userMaxMint = useMemo(
    () => devMintsAvailable
      || (saleDetails.status === 1 && userFreebiesAvailable)
      || Math.min(MAX_MINT_OVERRIDE || saleDetails.maxPurchase, saleDetails.totalTokensLeft),
    [
      devMintsAvailable,
      userFreebiesAvailable,
      saleDetails.status,
      saleDetails.maxPurchase,
      saleDetails.totalTokensLeft,
    ],
  );
  const userCanMint = useMemo(() => {
    if (!web3State.contractAddress) {
      // no contract
      return false;
    }
    if (loadingContract) {
      // loading contract details
      return false;
    }
    if (devMintsAvailable) {
      // dev override on everything else
      return true;
    }
    if (saleDetails.status === 2) {
      // public sale, we gucci by here
      return true;
    }
    if (saleDetails.status === 1) {
      // pre-sale, check for freebies or presale slots
      return !!userFreebiesAvailable || userPresaleSlotsAvailable > 0;
    }
    return false;
  }, [
    loadingContract,
    userFreebiesAvailable,
    devMintsAvailable,
    saleDetails.status,
    userPresaleSlotsAvailable,
    web3State.contractAddress,
  ]);
  useEffect(() => {
    setNumTokens(userMaxMint);
  }, [userMaxMint]);
  useEffect(() => {
    setError(null);
    if (!web3State.contractAddress) {
      setSaleDetails(initialSaleDetails);
      setError(`Contract does not exist on chain ${web3State.chainId}. You are either on the wrong chain or we have not deployed yet.`);
      return;
    }
    const isProd = process.env.NODE_ENV !== 'development';
    const prodChainId = supportedChains.find((chain) => chain.network === 'cronos');
    const testChainId = supportedChains.find((chain) => chain.network === 'tcronos');
    const isWrongChain = (isProd && web3State.chainId !== prodChainId)
      || (!isProd && !web3State.contractAddress);
    const targetChain = isProd ? prodChainId : testChainId;
    if (isWrongChain) {
      setError(`Your wallet is currently connected to the wrong chain. Please connect to ${targetChain.name}.`);
    }
  }, [web3State.connected, web3State.contractAddress, web3State.chainId]);
  useEffect(() => {
    (async () => {
      if (!web3State.contractAddress) return;
      setLoadingContract(true);
      setTxAddress(null);
      setError(null);
      const contractCall = async (methodName, param) => {
        let res;
        try {
          if (param) {
            res = await web3State.contract.methods[methodName](param).call();
          } else {
            res = await web3State.contract.methods[methodName]().call();
          }
        } catch (err) {
          console.error(err);
          throw new Error(`Error: Failed to receive a response while calling contract.methods.${methodName}. Please try refreshing.`);
        }
        return res;
      };
      try {
        const contractStatus = parseInt(await contractCall('saleStatus'), 10);
        const currentCount = parseInt(await contractCall('totalSupply'), 10);
        let presaleSlots = 0;
        let numFreebies = 0;
        let devMints = 0;
        let presalePrice = 0;
        if (contractStatus < 2) {
          presaleSlots = web3State.address ? parseInt(await contractCall('presaleList', web3State.address), 10) : 0;
          numFreebies = web3State.address ? parseInt(await contractCall('freebieList', web3State.address), 10) : 0;
          presalePrice = BigNumber.from(await contractCall('TOKEN_PRESALE_PRICE'));
          const totalDevMint = parseInt(await contractCall('RESERVED_TOKENS'), 10);
          const owner = await contractCall('owner');
          devMints = owner === web3State.address
            ? Math.max(0, totalDevMint - currentCount) : 0;
        }
        const maxCount = parseInt(await contractCall('MAX_SUPPLY'), 10);
        const maxPurchase = parseInt(await contractCall('MAX_PURCHASE'), 10);
        const price = BigNumber.from(await contractCall('TOKEN_PRICE'));
        const totalTokensLeft = maxCount - currentCount;
        let status = contractStatus;
        if (totalTokensLeft <= 0) {
          status = 3;
        }
        if (CLOSING_DATE && Date.now() > CLOSING_DATE.getTime()) {
          status = 4;
        }

        setUserPresaleSlotsAvailable(presaleSlots);
        setUserFreebiesAvailable(numFreebies);
        setDevMintsAvailable(devMints);
        setSaleDetails({
          status,
          currentCount,
          maxCount,
          totalTokensLeft,
          maxPurchase,
          price,
          presalePrice,
        });

        setLoadingContract(false);
      } catch (err) {
        setError((err && err.error) || err || GENERIC_ERROR);
        setLoadingContract(false);
        console.error(err);
      }
    })();
  }, [
    web3State.address,
    web3State.connected,
    web3State.chainId,
    web3State.contract,
    web3State.contractAddress,
  ]);

  // request access to the user's MetaMask account

  const mintToken = useCallback(async () => {
    if (!numTokens) return;
    try {
      ReactGA.event({
        category: 'web3 mint',
        action: 'click',
      });
      setError(null);
      setTxAddress(null);
      setLoading(true);
      const isDevMint = devMintsAvailable > 0;
      const isFreebieMint = !isDevMint && userFreebiesAvailable > 0;
      if (saleDetails.status === 1 && !isDevMint && !isFreebieMint && userPresaleSlotsAvailable <= 0) throw new Error('You aren\'t on the pre-sale list or you\'ve already minted once :(');
      let tx;
      if (isDevMint) {
        tx = await web3State.contract.methods.devMint(numTokens).send({
          from: web3State.address,
        });
        setDevMintsAvailable(devMintsAvailable - numTokens);
      } else if (isFreebieMint) {
        tx = await web3State.contract.methods.freebieMint(numTokens).send({
          from: web3State.address,
        });
        setUserFreebiesAvailable(userFreebiesAvailable - numTokens);
      } else if (saleDetails.status === 1) {
        tx = await web3State.contract.methods.presaleMint(numTokens).send({
          from: web3State.address,
          value: saleDetails.presalePrice.mul(numTokens),
        });
        setUserPresaleSlotsAvailable(userPresaleSlotsAvailable - 1);
      } else {
        tx = await web3State.contract.methods.publicSaleMint(numTokens).send({
          from: web3State.address,
          value: saleDetails.price.mul(numTokens),
        });
      }
      setTxAddress(tx && tx.transactionHash);
      setLoading(false);
      confettiBomb();
      ReactGA.event({
        category: 'web3 mint',
        action: 'result',
        label: 'success',
        nonInteraction: true,
      });
    } catch (err) {
      setError((err && err.error) || err || GENERIC_ERROR);
      setLoading(false);
      ReactGA.event({
        category: 'web3 mint',
        action: 'result',
        label: 'fail',
        nonInteraction: true,
      });
    }
  }, [
    numTokens,
    web3State.contract,
    web3State.address,
    saleDetails.status,
    saleDetails.price,
    saleDetails.presalePrice,
    devMintsAvailable,
    userPresaleSlotsAvailable,
    userFreebiesAvailable,
  ]);

  return (
    <div className={classnames('SectionMint', { 'SectionMint--connected': web3State.connected })}>
      {error || txAddress ? (
        <div className="SectionMint-section SectionMint-section--messages">
          {error ? (
            <p className="SectionMint-message SectionMint-message--error">
              {error.code ? `Error ${error.code}: ` : ''}
              {`${error.message || error}`}
            </p>
          ) : (
            ''
          )}
          {txAddress ? (
            <div className="SectionMint-message SectionMint-message--success">
              <p>
                Huzzah! You&apos;re now the proud owner of some booty!
                <br />
                Here is your transaction address:
                {' '}
                <AnchorAddress chainId={web3State.chainId} address={txAddress} type="tx" />
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      <SectionMintHeader
        isContractAvailable={!!web3State.contractAddress}
        saleStatus={saleDetails.status}
        totalTokensLeft={saleDetails.totalTokensLeft}
        userPresaleSlotsAvailable={userPresaleSlotsAvailable}
        userCanMint={userCanMint}
        walletConnected={web3State.connected}
        devMintsAvailable={devMintsAvailable}
        userFreebiesAvailable={userFreebiesAvailable}
        loadingContract={loadingContract}
      />
      {saleDetails.status < 3 && saleDetails.currentCount && saleDetails.currentCount > 200 ? (
        <div className="SectionMint-section SectionMint-section--count">
          <span className="SectionMint-sectionHeadline">
            inventory:
          </span>
          <p className={classnames({ 'SectionMint-message--error': saleDetails.totalTokensLeft / saleDetails.maxCount < 0.1 })}>
            Only
            {' '}
            {saleDetails.totalTokensLeft}
            {' '}
            tokens left!
          </p>
        </div>
      ) : ''}
      {web3State.connected ? (
        <>
          <div className="SectionMint-section SectionMint-section--wallet">
            <span className="SectionMint-sectionHeadline">
              wallet:
            </span>
            <AnchorWalletAddress />
          </div>
          {/* <div className="SectionMint-section SectionMint-section--contract">
            <span className="SectionMint-sectionHeadline">smart contract: </span>
            <AnchorAddress
              className="SectionMint-value"
              chainId={web3State.chainId}
              address={web3State.contractAddress}
            />
          </div> */}
          {userCanMint ? (
            <>
              <div className="SectionMint-section SectionMint-section--qty">
                <label className="SectionMint-inputLabel" htmlFor="token-quantity">
                  <span className="SectionMint-sectionHeadline">
                    qty of tokens:
                  </span>
                  <div className="SectionMint-inputNoteWrapper">
                    <input
                      id="token-quantity"
                      className="SectionMint-input SectionMint-value"
                      onChange={(e) => setNumTokens(parseInt(e.target.value, 10))}
                      type="number"
                      min="1"
                      max={userMaxMint}
                      required
                      onWheel={(e) => e.target.blur()}
                      value={numTokens}
                    />
                    <span
                      className={classnames(
                        'SectionMint-inputNote',
                        {
                          'SectionMint-inputNote--discount': ((saleDetails.status === 1 && (userFreebiesAvailable || userPresaleSlotsAvailable)) || devMintsAvailable),
                        },
                      )}
                    >
                      {bigNumberWeiToEth(saleDetails.price)}
                      {' '}
                      CRO
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {(saleDetails.status === 1 && userFreebiesAvailable) || devMintsAvailable ? (
                        <span className="SectionMint-inputNoteNewPrice">FREE</span>
                      ) : saleDetails.status === 1 && userPresaleSlotsAvailable ? (
                        <span className="SectionMint-inputNoteNewPrice">
                          {bigNumberWeiToEth(saleDetails.presalePrice)}
                          {' '}
                          CRO
                        </span>
                      ) : ''}
                    </span>
                  </div>
                </label>
              </div>
              <div className="SectionMint-section SectionMint-section--confirm">
                <button
                  className={
                  classnames(
                    'SectionMint-confirm',
                    'Button',
                    {
                      'Button--disabled': !userCanMint || !web3State.connected,
                      'Button--loading': loading,
                    },
                  )
                }
                  type="button"
                  disabled={loading || !userCanMint || !web3State.connected}
                  onClick={mintToken}
                >
                  <span className="Button-text">
                    Confirm Butts
                  </span>
                </button>
              </div>
            </>
          ) : ''}
        </>
      ) : (
        <div className="SectionMint-section SectionMint-section--connect">
          {(() => {
            if (saleDetails.status < 0) return '';
            if (web3State.connected) {
              return (<AnchorWalletAddress />);
            }
            if (saleDetails.status >= 3) {
              return (
                <a
                  href={appConfig.EBISUS_BAY_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="Button"
                >
                  <span className="Button-text">
                    View on Ebisu&apos;s Bay
                  </span>
                </a>
              );
            }
            return (
              <ButtonConnect
                className="SectionMint-connect"
                // disabled={
                //   !web3State.contractAddress
                //   || !saleDetails.status
                //   || saleDetails.status < 1
                //   || saleDetails.status > 2
                // }
              />
            );
          })()}
        </div>
      )}
    </div>
  );
}

export default SectionMint;
