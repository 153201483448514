import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Accordion.css';

export function Accordion({ title, children, className }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className={classNames(
        'Accordion',
        {
          'Accordion--open': isActive,
          'Accordion--closed': !isActive,
        },
        className,
      )}
    >
      <button
        type="button"
        className="Accordion-title"
        onClick={() => setIsActive(!isActive)}
      >
        {title}
      </button>
      <div className="Accordion-content">
        {children}
      </div>
    </div>
  );
}

Accordion.defaultProps = {
  className: '',
};

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Accordion;
