import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactGA from 'react-ga4';

import { useWeb3Context, web3Connect } from '../context/Web3Context';

const GENERIC_ERROR = {
  code: -1,
  message: 'Unknown error',
};

function ButtonConnect({ className, disabled }) {
  const {
    web3Dispatch,
  } = useWeb3Context();
  const [loadingConnection, setLoadingConnection] = useState(false);
  async function connect() {
    try {
      ReactGA.event({
        category: 'web3 connect',
        action: 'click',
      });
      setLoadingConnection(true);
      web3Dispatch(await web3Connect());
      ReactGA.event({
        category: 'web3 connect',
        action: 'result',
        label: 'success',
        nonInteraction: true,
      });
    } catch (err) {
      console.error((err && err.error) || err || GENERIC_ERROR);
      setLoadingConnection(false);
      ReactGA.event({
        category: 'web3 connect',
        action: 'result',
        label: 'fail',
        nonInteraction: true,
      });
    }
  }
  return (
    <button
      className={
        classnames(
          className,
          'ButtonConnect',
          'Button',
          {
            'Button--loading': loadingConnection,
            'Button--disabled': disabled,
          },
        )
      }
      disabled={disabled || loadingConnection}
      type="button"
      onClick={connect}
    >
      <span className="Button-text">
        Connect Wallet
      </span>
    </button>
  );
}

ButtonConnect.defaultProps = {
  className: '',
  disabled: false,
};

ButtonConnect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ButtonConnect;
