import confetti from 'canvas-confetti';
import { shuffleArray } from './shuffleArray';

function launchConfetti(origin = { y: 0.6667 }) {
  const colors = ['#ff0000', '#ffcc00', '#2381fa', '#ddd'];
  const count = 200;

  function fire(particleRatio, opts) {
    confetti({
      origin,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
    });
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
    colors: shuffleArray(colors),
  });
  fire(0.2, {
    spread: 60,
    colors: shuffleArray(colors),
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
    colors: shuffleArray(colors),
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
    colors: shuffleArray(colors),
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
    colors: shuffleArray(colors),
  });
}

export default function confettiBomb() {
  const duration = 100;
  const start = Date.now();
  const end = start + duration;
  (function frame() {
    launchConfetti({ x: Math.random(), y: Math.random() });
    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  }());
}

const goal = ['b', 'u', 't', 't', 'l', 'u', 'v', 'e', 'r'];
let cur = 0;
function locked(e) {
  if (e.key === goal[cur]) {
    cur += 1;
  } else {
    cur = 0;
  }
  if (cur >= goal.length) {
    confettiBomb(true);
    window.removeEventListener('keyup', locked);
    window.addEventListener('keyup', () => launchConfetti({ x: Math.random(), y: Math.random() }));
  }
}
window.addEventListener('keyup', locked);
