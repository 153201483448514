import './App.css';
import React, { useState } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import PageHome from './PageHome';
import PageInfo from './PageInfo';
import PageTerms from './PageTerms';
import PageGallery from './PageGallery';
import PageProvenance from './PageProvenance';
import Analytics from './Analytics';

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(!!document.cookie.split(';').find((c) => c === 'CookieConsent=true'));
  return (
    <>
      <Router>
        <Analytics cookiesAccepted={cookiesAccepted} />
        <div className="App">
          <div className="App-inner">
            <AppHeader className="App-header" />
            <main className="App-main">
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<PageHome />}
                />
                <Route
                  exact
                  path="/info"
                  element={<PageInfo />}
                />
                <Route
                  exact
                  path="/provenance"
                  element={<PageProvenance />}
                />
                <Route
                  exact
                  path="/terms"
                  element={<PageTerms />}
                />
                <Route
                  exact
                  path="/gallery"
                  element={<PageGallery />}
                />
              </Routes>
            </main>
          </div>
          <AppFooter className="App-footer" />
        </div>
      </Router>
      <CookieConsent
        buttonText="Accept"
        style={{ backgroundColor: '#000' }}
        buttonStyle={{
          backgroundColor: '#ff0000',
          color: '#FFFCF9',
          fontWeight: '700',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
        onAccept={() => {
          setCookiesAccepted(true);
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  );
}

export default App;
