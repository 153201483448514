import React from 'react';
import { Link } from 'react-router-dom';
import SectionMint from './SectionMint';
import PreviewGifSrc from '../media/preview.gif';
import PreviewVideoSrc from '../media/preview.mp4';
import OgPreviewSrc from '../media/og-preview.png';

import config from '../data/app.json';

import './PageHome.css';

function PageHome() {
  return (
    <div className="PageHome">
      <div className="PageHome-section PageHome-section--intro">
        <div className="PageHome-sectionMain">
          <div className="PageHome-sectionMainInner">
            <video className="PageHome-previewVideo" playsInline autoPlay muted loop>
              <source src={PreviewVideoSrc} />
            </video>
            <p className="PageHome-blurb">
              {config.TOKEN_TAG}
            </p>
          </div>
          <SectionMint />
        </div>
      </div>
      <div className="PageHome-section PageHome-section--imageText PageHome-section--details">
        <div className="PageHome-sectionInner">
          <div className="PageHome-sectionMain">
            <h2 className="PageHome-headline">Give me the deets</h2>
            <p className="PageHome-blurb">
              {config.PROJECT_NAME}
              {' '}
              is a limited collection of
              {' '}
              {(config.TOKEN_COUNT_FINAL || config.TOKEN_COUNT).toLocaleString()}
              {' '}
              vibrant and colorful NFT tokens living on
              the Cronos blockchain. Each token is created by combining
              up to 14 traits from a collection of over 300 assets with the exception
              of 56 hand-crafted extremely rare tokens sprinkled throughout the project!
              Ready to dive in? Mint a few butts above, trade them on
              {' '}
              <a
                className="TextIcon TextIcon--ebisus"
                href={config.EBISUS_BAY_URL}
                target="_blank"
                rel="noreferrer"
              >
                Ebisu&apos;s Bay
              </a>
              , or browse
              {' '}
              <Link to="/gallery">the gallery</Link>
              .
            </p>
            <p className="PageHome-blurb">
              All token images and metadata are built to last.
              Check out
              {' '}
              <Link to="/provenance">the provenance page</Link>
              {' '}
              for more info on how we strive to make them outlive you.
            </p>
            <p className="PageHome-blurb">
              <strong>
                We have not planned a roadmap. The future is filled with butts. Just butts.
              </strong>
            </p>
          </div>
          <a
            className="PageHome-sectionImg PageHome-sectionImg--preview"
            href={config.EBISUS_BAY_URL}
            target="_blank"
            rel="noreferrer"
          >
            <img src={PreviewGifSrc} alt={`A preview of a few ${config.TOKEN_NAME_PLURAL}`} />
          </a>
        </div>
      </div>
      <div className="PageHome-section PageHome-section--imageText PageHome-section--legacy">
        <div className="PageHome-sectionInner">
          <div className="PageHome-sectionMain">
            <h2 className="PageHome-headline">
              Where are
              <br />
              OG ButtPunks?!
            </h2>
            <p className="PageHome-blurb">
              All 2,604 OG ButtPunks are alive and well on the Ethereum network!
              We&apos;ve migrated the website over to
              {' '}
              <a
                className="AnchorLink"
                href="https://buttpunks.com/og/"
                target="_blank"
                rel="noreferrer"
              >
                a new URL
              </a>
              {' '}
              to make room for v2 butts.
            </p>
            <p className="PageHome-blurb">
              If you&apos;re new to ButtPunks make sure to check out the entire collection
              {' '}
              <a
                className="AnchorLink TextIcon TextIcon--opensea"
                href="https://opensea.io/collection/ogbuttpunks"
                target="_blank"
                rel="noreferrer"
              >
                on OpenSea
              </a>
              {' '}
              to see the original cheeks!
            </p>
          </div>
          <a
            className="PageHome-sectionImg PageHome-sectionImg--preview"
            href="https://buttpunks.com/og/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={OgPreviewSrc} alt="OG ButtPunks Logo over an OG ButtPunk" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default PageHome;
