import classNames from 'classnames';
import React, { useMemo } from 'react';
import {
  setPaginatorPage,
  usePaginatorContext,
} from './PaginatorContext';

import './styles.css';

const PAGE_LINKS = 3;

export function PaginatorControls() {
  const { paginatorState, paginatorDispatch } = usePaginatorContext();
  const pages = useMemo(() => Array.from(new Array(paginatorState.numPages))
    .map((val, index) => index), [paginatorState.numPages]);
  const condensedPages = useMemo(() => {
    if (pages.length <= 1) return null;
    if (pages.length <= PAGE_LINKS) return pages;
    const startIndex = Math.max(0, paginatorState.curPage - Math.floor(PAGE_LINKS / 2));
    const endIndex = Math.min(
      pages.length - 1,
      paginatorState.curPage + Math.floor(PAGE_LINKS / 2),
    );
    const newPages = pages.slice(startIndex, endIndex + 1);
    return newPages;
  }, [pages, paginatorState.curPage]);
  // const pageLabels = useMemo(() => pages.map((page) => {
  //   const startItemIndex = parseInt(page * paginatorState.numPerPage, 10);
  //   const startItem = paginatorState.items[startItemIndex];
  //   const endItemIndex = parseInt(Math.min(
  //     startItemIndex + paginatorState.numPerPage - 1,
  //     paginatorState.items.length - 1,
  //   ), 10);
  //   const endItem = paginatorState.items[endItemIndex];
  //   return `#${startItem.tokenId} - #${endItem.tokenId}`;
  // }), [pages, paginatorState.numPerPage, paginatorState.items]);
  if (pages.length <= 1) return '';
  return (
    <div className="Paginator">
      <div className="Paginator-inner">
        {/* <select
        className="PaginatorSelect"
        value={paginatorState.curPage}
        onChange={(event) => paginatorDispatch(setPaginatorPage(parseInt(event.target.value, 10)))}
        >
        {pages.map((page) => (
          <option
          key={page}
          value={page}
          className={classNames(
            'Paginator-option',
            {
              'Paginator-option--current': page === paginatorState.curPage,
            },
            )}
            >
            {pageLabels[page]}
            </option>
            ))}
          </select> */}
        <div className="Paginator-pageLinks">
          {condensedPages && condensedPages[0] !== 0 ? (
            <>
              <button
                type="button"
                className="Paginator-pageLink"
                onClick={() => paginatorDispatch(setPaginatorPage(0))}
              >
                0
              </button>
              {condensedPages[0] !== 1 ? '...' : ''}
            </>
          ) : ''}
          {condensedPages && condensedPages.map((page) => (
            <button
              key={page}
              type="button"
              className={classNames(
                'Paginator-pageLink',
                {
                  'Paginator-pageLink--current': page === paginatorState.curPage,
                },
              )}
              onClick={() => paginatorDispatch(setPaginatorPage(page))}
            >
              {page}
            </button>
          ))}
          {condensedPages && condensedPages[condensedPages.length - 1] !== pages.length - 1 ? (
            <>
              {condensedPages[condensedPages.length - 1] !== pages.length - 2 ? '...' : ''}
              <button
                type="button"
                className="Paginator-pageLink"
                onClick={() => paginatorDispatch(setPaginatorPage(pages.length - 1))}
              >
                {pages.length - 1}
              </button>
            </>
          ) : ''}
        </div>
        <div className="Paginator-buttonWrapper">
          <button
            className="Paginator-button"
            type="button"
            disabled={paginatorState.curPage <= 0}
            onClick={() => paginatorDispatch(setPaginatorPage(paginatorState.curPage - 1))}
          >
            &lt;
          </button>
          <button
            className="Paginator-button"
            type="button"
            disabled={paginatorState.curPage >= paginatorState.numPages - 1}
            onClick={() => paginatorDispatch(setPaginatorPage(paginatorState.curPage + 1))}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaginatorControls;
